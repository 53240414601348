import { PaginationState } from '@tanstack/react-table';
import { CalendarRange, GitCompare, Home, PackageSearch, Radar } from 'lucide-react';

import { FeatureNotification } from '@/hooks/use-feature-notifications';
import { FEATURE } from '@/lib/constants/feature-notifications';
import {
  DelayStatus,
  MapPathStyle,
  NavGroupInterface,
  SubscriptionStatusInterface,
  UserEntitlementInterface,
} from '@/types';
import { FuelBurnUnitType } from '@/types/api-types';

export const SITE_NAME = 'Fluent Cargo';
export const SITE_DOMAIN = 'https://www.fluentcargo.com';
export const SITE_CONTACT_EMAIL = 'contact@fluentcargo.com';

export const DEFAULT_API_VERSION = '7';
export const REFRESH_HEADER_KEY = 'x-refresh';
export const TRIAL_LENGTH_DAYS = 10;

// Google Ads
export const ADS_PUB_ID = 'ca-pub-9938321624120734';
export const ADS_AIR_ROUTE_SLOT = '4476427244';
export const ADS_OCEAN_ROUTE_SLOT = '4955773541';
export const ADS_ROUTE_SIDEBAR_SLOT = '4803400738';

// Content Pages
export const PAGE_REVALIDATE_INTERVAL = 60 * 60; // 1 hour (default)
export const PAGE_REVALIDATE_INTERVAL_DAY = PAGE_REVALIDATE_INTERVAL * 24; // 1 day
export const PAGE_REVALIDATE_INTERVAL_WEEK = PAGE_REVALIDATE_INTERVAL_DAY * 7; // 1 week
export const PAGE_REVALIDATE_INTERVAL_MONTH = PAGE_REVALIDATE_INTERVAL_DAY * 28; // 1 month

export const CENTER_CONTENT_MAX_WIDTH = '1270px';
export const NARROW_CENTER_CONTENT_MAX_WIDTH = '640px';

export const HOME_URL = '/dashboard';
export const ROUTING_SEARCH_URL = '/search';
export const PORTS_SEARCH_URL = '/search/ports';
export const SCHEDULE_SEARCH_URL = '/search/schedules';
export const TRACKING_URL = '/tracking';
export const TRACKING_IMPORT_URL = `${TRACKING_URL}/import`;
export const TRACKING_REQUESTS_URL = `${TRACKING_URL}/import/pending`;
export const TRACKING_IMPORT_SINGLE_URL = `${TRACKING_URL}/import/single`;
export const TRACKING_IMPORT_BATCH_URL = `${TRACKING_URL}/import/multiple`;

// Forms
export const INPUT_HEIGHT = '42px';
export const INPUT_HEIGHT_SMALL = '32px';
export const DROPDOWN_WIDTH = '320px';

// Filters
export const FILTER_ALL = 'all';
export const FILTER_TRANSPORT = 'transport';
export const FILTER_CARRIERS = 'carriers';
export const FILTER_LOCATIONS = 'locations';
export const FILTER_BODYTYPE = 'bodyType';
export const FILTER_HAULING = 'hauling';
export const FILTER_MAX_TRANSFERS = 'maxTransfers';

// Standalone Schedule filters
export const FILTER_CARRIER_IDS = 'carrierIds';
export const FILTER_DIRECT_SERVICES_ONLY = 'directServicesOnly';
export const FILTER_AIRCRAFT_TYPE = 'aircraftType';

// Filter Vehicle attributes
export const FILTER_BODYTYPE_WIDE = 'widebody';
export const FILTER_BODYTYPE_NARROW = 'narrowbody';
export const FILTER_HAULING_FREIGHT = 'freight';
export const FILTER_HAULING_PASSENGERS = 'passengers';
export const FILTER_HAULING_CARGO_AIRCRAFT_ONLY = 'cargoAircraftOnly';

// Via/avoid
export const VIA = 'via';
export const AVOID = 'avoid';

// Depart/arrive
export const DEPART = 'depart';
export const ARRIVE = 'arrive';

// Route Labels
export const QUICKEST_ROUTE = 'quickest';
export const MOST_FREQUENT_ROUTE = 'mostFrequent';
export const SHORTEST_ROUTE = 'shortest';
export const FREIGHTER_ROUTE = 'hasFreighter';

// Route sort types
export const ROUTE_SORT_TYPE_DEFAULT = 'default';
export const ROUTE_SORT_TYPE_DURATION = 'duration';
export const ROUTE_SORT_TYPE_COMPLEXITY = 'complexity';
export const ROUTE_SORT_TYPE_DISTANCE = 'distance';
export const ROUTE_SORT_TYPE_FREQUENCY = 'frequency';
export const ROUTE_SORT_TYPE_PRICE = 'price';
export const ROUTE_SORT_TYPE_CO2 = 'co2';
export const ROUTE_SORT_TYPE_MATCH = 'bestMatch';

// Block Attribute availability
export const NONE_AVAILABLE = 'none';
export const PARTIAL_AVAILABLE = 'partial';
export const ENTIRE_AVAILABLE = 'entire';

// Mailchimp
export const { MAILCHIMP_API_TOKEN } = process.env;
export const { MAILCHIMP_LIST_ID } = process.env;

// Date Formats
export const DATE_WEEK_REGEX = /^\d{4}-W\d{2}$/; // yyyy-Ww
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/; // yyyy-mm-dd
export const ISO_WEEK_FORMAT = "yyyy-'W'II"; // is ISO Week with leading zero
export const ISO_DATE_FORMAT = 'yyyy-MM-dd'; // 2022-01-09
export const ISO_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'.000"; // 2022-01-09T01:59:59.000
export const URL_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss"; // 2022-01-09T01:59:59 // different to above :(
export const KEY_DATE_FORMAT = 'yyyyMMdd'; // 20220109
export const DATE_FORMAT = 'dd MMM yyyy'; // e.g. 09 Jan 2022
export const DATE_MONTH_FORMAT = 'd MMM'; // e.g. 9 Jan
export const DAY_LABEL_FORMAT = 'd'; // 9
export const DAY_DATE_FORMAT = 'EEE, d MMM'; // Tue, 09 Jan
export const MONTH_LABEL_FORMAT = 'MMMM'; // January
export const SHORT_MONTH_LABEL_FORMAT = 'MMM'; // Jan
export const TIME_FORMAT = 'HH:mm'; // 7:23
export const DATETIME_FORMAT = 'd MMM, HH:mm'; // 09 Jan, 7:23
export const FULL_DATETIME_FORMAT = 'dd MMM yyyy, HH:mm'; // 09 Jan 2022, 7:23
export const ISO_DATETIME_FORMAT = 'yyyy-MM-dd, HH:mm'; // 2022-01-09, 7:23

export const DAYS_OF_WEEK = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// Node Types
// type NodeType = 'unknown' | 'airport' | 'seaport' | 'trainStation' | 'city' | 'carrier' | 'vehicle';
export const SEAPORT = 'seaport';
export const AIRPORT = 'airport';
export const TRAIN_STATION = 'trainStation';
export const RAIL_TERMINAL = 'railTerminal';
export const CITY = 'city';
export const UNKNOWN = 'unknown';
export const CARRIER = 'carrier';
export const VEHICLE = 'vehicle';

// Mode Types
export const MODE_TRANSFER = 'transfer';
export const MODE_RAIL = 'rail';
export const MODE_ROAD = 'road';
export const MODE_WATER = 'water';
export const MODE_AIR = 'air';
export const MODE_INTERMODAL = 'intermodal';

// Alt Mode Types
export const MODE_SHIP = 'ship';
export const MODE_PLANE = 'plane';
export const MODE_TRUCK = 'truck';
export const MODE_OCEAN = 'ocean';

// Carrier Types
export const CARRIER_AIRLINE = 'Airline';
export const CARRIER_OCEAN = 'Shipping Line';
export const CARRIER_RAIL = 'Rail operator';

// Ocean Vessels
export const VESSEL_CONTAINER = 'Container';
export const VESSEL_BULK = 'Bulk Carrier';
export const VESSEL_GENERAL = 'General Cargo';
export const VESSEL_PASSENGER = 'Passenger';
export const VESSEL_TANKER = 'Tanker';
export const VESSEL_GAS = 'Gas Carrier';
export const VESSEL_REEFER = 'Reefer';
export const VESSEL_RORO = 'RoRo';

export const CARGO_TYPE_CONTAINER = 'container';
export const CARGO_TYPE_ITEM = 'item';
export const CARGO_TYPE_ULD = 'uld';

// Transfer Types
export const TRANSFER_MODE = 'modeChange';
export const TRANSFER_STOP = 'stop';
export const TRANSFER_TRANSFER = 'transfer';
export const CANAL_TRANSIT = 'canalThru';
export const BORDER_CROSSING = 'border-crossing';
export const WAYPOINT = 'waypoint';

// Identifier Types
// export type IdentifierApiType = | 'unknown' | 'nodeId' | 'componentId' | 'faa' | 'ds100' | 'iata' | 'icao' | 'locode' | 'scac' | 'callSign' | 'linkKey';
export const ID_NODEID = 'nodeId';
export const ID_IATA = 'iata';
export const ID_ICAO = 'icao';
export const ID_LOCODE = 'locode';
export const ID_SCAC = 'scac';
export const ID_IMO = 'vesselImo';
export const ID_CANONICAL = 'canonical';

// Place Types
export const PLACE_COUNTRY = 'country';
export const PLACE_REGION = 'region';
export const PLACE_CITY = 'city';
export const PLACE_LOCALITY = 'locality';
export const PLACE_ADDRESS = 'address';
export const PLACE_PREMISE = 'premise';
export const PLACE_GENERIC = 'place';
export const PLACE_PORT = 'port';

export const UNIT_MAXHEIGHT = 'maxHeight';
export const UNIT_WEIGHT = 'weight';
export const UNIT_VOLUME = 'volume';

export const UNIT_DISTANCE_CM = 'CM'; // Centimetres
export const UNIT_DISTANCE_IN = 'IN'; // Inches
export const UNIT_WEIGHT_KG = 'KG'; // Kilograms
export const UNIT_WEIGHT_LB = 'LB'; // Pounds
export const UNIT_VOLUME_CBM = 'CBM'; // Cubic Meters
export const UNIT_VOLUME_CFT = 'CFT'; // Cubic Feet
export const UNIT_VOLUME_CBM_FULL = 'Cubic meters'; // Cubic Meters
export const UNIT_VOLUME_CFT_FULL = 'Cubic feet'; // Cubic Feet

export const UNIT_CO2 = 'CO₂';
export const UNIT_KG = 'kg'; // duplicate of above KG but lowercase?
export const UNIT_TONNE = 't';
export const UNIT_CO2_TEU: FuelBurnUnitType = 'co2PerTeu';
export const UNIT_CO2_KG: FuelBurnUnitType = 'co2PerKg';
export const UNIT_CO2_UNKNOWN: FuelBurnUnitType = 'unknown';

export const AIRCRAFT_TYPE_OPTIONS = {
  FREIGHTER: 'Freighter',
  WIDEBODY: 'PAX - Widebody',
  NARROWBODY: 'PAX - Narrowbody',
};

// Disruption Alerts
export const ALERT_TYPE = {
  WARNING: 'warning',
  DISRUPTION: 'disruption',
  CONGESTION: 'congestion',
  WEATHER: 'weather',
  CLOSURE: 'closure',
  WARZONE: 'warzone',
  OTHER: 'other',
} as const;

export const ALERT_LEVEL = {
  EXTREME: 'extreme',
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
} as const;

// Pricing Types
export const PRICING_UNIT_TYPES = {
  UNKNOWN: 'unknown',
  PER_WEIGHT: 'perWeight',
  PER_CONTAINER: 'perContainer',
};

export const PRICING_PER_UNIT_TEXT = {
  [MODE_AIR]: 'per kg',
  [MODE_WATER]: 'per 40" container (FEU)',
  [`${MODE_WATER}-short`]: 'per FEU',
  [MODE_ROAD]: 'per km/mile',
};

// Map Constants
export const MAP_LINE_COLOR = '#0570BF';
export const MAP_LINE_COLOR_ACTIVE = '#fff';
export const MAP_LINE_STYLE_SOLID = 'solid';
export const MAP_LINE_STYLE_DASHED = 'dashed';
export const MAP_LINE_STYLE_DASHED_LIGHT = 'dashed-light';

export const MAP_LINE_COLOR_AIR = '#8CC247';
export const MAP_LINE_COLOR_WATER = '#1EA8D2';
export const MAP_LINE_COLOR_RAIL = '#F5A357';
export const MAP_LINE_COLOR_ROAD = '#7B7B7B';
export const MAP_LINE_COLOR_LIVE = '#34A8FF';
export const MAP_LINE_COLOR_UNKNOWN = '#8a8a8a';

export const MAP_PATH_STYLE: MapPathStyle = {
  pathStyle: MAP_LINE_STYLE_SOLID,
  pathColor: MAP_LINE_COLOR,
  hasOutline: true,
  outlineOpacity: 0.2,
};

// Autocomplete Constants
export const AUTOCOMPLETE_ALGOLIA = 'algolia';
export const AUTOCOMPLETE_TYPESENSE = 'typesense';
export const AUTOCOMPLETE_GOOGLE = 'google';
export const AUTOCOMPLETE_PLACE_TYPES = [PLACE_GENERIC, AIRPORT, SEAPORT] as const;

export const API_MAX_CACHE = 60 * 24 * 30; // 30 days
export const API_1_WEEK_CACHE = 60 * 24 * 7; // 7 days
export const API_1_DAY_CACHE = 60 * 24; // 1 day
export const API_1_HOUR_CACHE = 60; // 1 hour

// Arbitrary times
export const TEN_MINS = 1000 * 60 * 10;

// Port insights
export const DEFAULT_SEARCH_RADIUS_IN_KM = '200';

export const DEFAULT_AIRPORT_COUNT = 50;
export const DEFAULT_SEAPORT_COUNT = 20;

export const DISTANCE_UNITS = {
  KM: 'km',
  MI: 'miles',
  NM: 'ni.', /// nautical miles
};

export const CONGESTION_ATTRIBUTES = {
  DAYS_AT_PORT: 'daysAtPort',
  DAYS_AT_ANCHORAGE: 'daysAtAnchorage',
  DAYS_TOTAL: 'daysTotal',
};

export const DELAY_STATUS: Record<'ONTIME' | 'DELAYED' | 'AHEAD' | 'IN_TRANSIT' | 'ARRIVED', DelayStatus> = {
  ONTIME: 'ontime',
  DELAYED: 'delayed',
  AHEAD: 'ahead',
  IN_TRANSIT: 'intransit',
  ARRIVED: 'arrived',
};

// Analytics
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || 'ABCD';

export const GOOGLE_ADS_ID = process.env.NEXT_PUBLIC_GOOGLE_ADS_ID || 'ABCD';

// Algolia Indexes
export const ALGOLIA_PORTS_INDEX_NAME = 'Ports';
export const ALGOLIA_CARRIERS_INDEX_NAME = 'Carriers';

/**
 * SKU for access levels
 * XX1-01-01
 *
 * We're currently bound by a bug in the Supabase adapter (The isDate function
 * is too basic so considers numbers and other non-dates as dates) so
 * we can't hypenate the first two parts of the SKU
 *
 * XX - Country code
 * 1 - Product type
 * 01 - Access Level/pricing tier
 * 01 - Feature
 */
export const USER_ACCESS_LEVELS = {
  ADMIN: 'admin',
  XX1_00_01: 'XX1-00-01',
  XX1_01_01: 'XX1-01-01',
  XX1_02_01: 'XX1-02-01',
  XX1_03_01: 'XX1-03-01',
  XX1_04_01: 'XX1-04-01',
  XX1_05_01: 'XX1-05-01',
  XX1_06_01: 'XX1-06-01',
  XX1_07_01: 'XX1-07-01',
  XX1_08_01: 'XX1-08-01',
  XX1_09_01: 'XX1-09-01',
  // Special access level for Fluent Admins
  XX1_09_FLC: 'XX1-09-FLC',
};

export const USER_ROLES = {
  ADMIN: 'admin',
  MEMBER: 'member',
};

export const USER_ACCESS_FEATURES = {
  ROUTE_DETAILS: 'routeDetails',
  ROUTE_FILTERS: 'routeFilters',
  ADVANCED_SEARCH: 'advancedSearch',
  CHARTS: 'charts',
  SCHEDULES: 'schedules',
  SEGMENT_DETAILS: 'segmentDetails',
  BOOKING: 'booking',
  HISTORY: 'history',
  PRICING: 'pricing',
  EMISSIONS: 'emissions',
  SAVED_ITEMS: 'savedItems',
  TRACKING: 'tracking',
  ADVANCED_TRACKING: 'advancedTracking',
} as const;

// Default user entitlements, database entitlements extend from this
export const DEFAULT_ENTITLEMENTS: UserEntitlementInterface = {
  accessLevel: 'DEFAULT',
  routeSearches: 30,
  scheduleSearches: 15,
  memberLimit: 1,
  trackedItemsLimit: 0,
  [USER_ACCESS_FEATURES.ROUTE_DETAILS]: true,
  [USER_ACCESS_FEATURES.SEGMENT_DETAILS]: true,
  [USER_ACCESS_FEATURES.ROUTE_FILTERS]: true,
  [USER_ACCESS_FEATURES.HISTORY]: false,
  [USER_ACCESS_FEATURES.ADVANCED_SEARCH]: false,
  [USER_ACCESS_FEATURES.SCHEDULES]: false,
  [USER_ACCESS_FEATURES.CHARTS]: false,
  [USER_ACCESS_FEATURES.PRICING]: false,
  [USER_ACCESS_FEATURES.EMISSIONS]: false,
  [USER_ACCESS_FEATURES.ADVANCED_TRACKING]: false,
};

export const PLAN_TIER_NONE = 'none';
export const PLAN_TIER_FREE = 'free';
export const PLAN_TIER_PAID = 'paid';
export const PLAN_TIER_TRIAL = 'trial';

export const SUBSCRIPTION_STATUSES: SubscriptionStatusInterface = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  PAST_DUE: 'past_due',
  PAUSED: 'paused',
  TRIALING: 'trialing',
  UNPAID: 'unpaid',
} as const;

// This is a custom non-Stripe status we use to indicate
// that the user has no subscription on the orgs page
// This needs to be seperate from the Stripe statuses
// to avoid type conflicts
export const SUBSCRIPTION_STATUS_NONE = 'no_subscription';

export const INVOICE_STATUS_DRAFT = 'draft';
export const INVOICE_STATUS_OPEN = 'open';
export const INVOICE_STATUS_PAID = 'paid';
export const INVOICE_STATUS_UNCOLLECTABLE = 'uncollectible';
export const INVOICE_STATUS_VOID = 'void';

export const ENQUIRY_TYPES = {
  GENERAL_ENQUIRY: 'General enquiry',
  SHIPPING_ENQUIRY: 'Shipping enquiry',
  PARTNERSHIP_ENQUIRY: 'Partnership enquiry',
  ENTERPRISE_PRICING: 'Enterprise Pricing request',
  GIVE_US_FEEDBACK: 'Give us feedback',
  OTHER: 'Other',
};

export const CONTACT_FORM_FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  ENQUIRY_TYPE: 'enquiryType',
  PICKUP_ADDRESS: 'pickupAddress',
  DROPOFF_ADDRESS: 'dropoffAddress',
  CARGO_DESCRIPTION: 'cargoDescription',
  SUBJECT_OF_ENQUIRY: 'subjectOfEnquiry',
  MESSAGE: 'message',
  NEWSLETTER: 'subscribeToNewsletter',
} as const;

export const SHIPMENT_FORM_FIELDS = {
  REFERENCE_NUMBER: 'referenceNumber',
  REFERENCE_NUMBER_TYPE: 'referenceNumberType',
} as const;

export const USER_FORM_FIELDS = {
  PASSWORD: 'password',
  PASSWORD_STRENGTH_SCORE: 'passwordStrengthScore',
  SHOW_PASSWORD: 'showPassword',
  CONFIRM_PASSWORD: 'confirmPassword',
  USAGE_PURPOSE: 'usagePurpose',
  EMAIL: 'email',
  NAME: 'name',
  COUNTRY: 'country',
  BUSINESS_NAME: 'businessName',
  BUSINESS_TYPE: 'businessType',
  BUSINESS_EMAIL: 'businessEmail',
  IS_FREIGHT_FORWARDER: 'isFreightForwarder',
} as const;

// Used to populate the query string 'createAccount' on the signin page
export const CREATE_ACCOUNT_QUERY_STRING = 'createAccount';
export const SHOULD_CREATE_ACCOUNT = 'true';
export const SIGN_IN_PAGE_URL = '/auth/signin';
export const USER_PAGE_URL = '/auth/user';
export const ORG_INVITES_URL = '/auth/user/invites';

// LinkedIn Conversion Goals
export const LINKEDIN_GOAL_SIGNIN = '12561260';
export const LINKEDIN_GOAL_SEARCH = '12561268';

export const RESPONSIVE_IMAGE_WORLD_MAP = {
  responsiveImage: {
    src: '/assets/map-background.png',
    aspectRatio: 1.6,
    width: 750,
  },
};

export const RESPONSIVE_IMAGE_WORLD_MAP_DARK = {
  responsiveImage: {
    src: '/assets/map-background-dark.png',
    aspectRatio: 1.6,
    width: 750,
  },
};

export const ENVS = {
  LOCAL: 'local',
  PROD: 'production',
  PREVIEW: 'preview',
  DEVELOP_BRANCH: 'develop_branch',
};

export const USER_MESSAGES = {
  INVALID_CAPTCHA: 'Unproccesable request, invalid captcha code',
  SIGN_IN_FAILED: 'Sign in failed',
  TRY_AGAIN: 'Please try again but if you continue having issues please contact us directly at contact@fluentcargo.com',
};

export const API_RESPONSE_MESSAGES = {
  401: 'Unauthorised',
};

export const NEXT_AUTH_STATUSES = {
  LOADING: 'loading',
  UNAUTHENTICATED: 'unauthenticated',
  AUTHENTICATED: 'authenticated',
};

export const NEXT_AUTH_TOKEN_PREFIXES = {
  VERIFICATION_TOKEN: 'VT#',
  PASSWORD_RESET: 'PR-',
};

export const SUPABASE_TABLE_NAMES = {
  ORGS: 'orgs',
  ORGS_USERS: 'orgs_users',
  INVITES: 'invites',
  USERS: 'users',
  ENTITLEMENTS: 'entitlements',
  PLANS: 'plans',
  PLAN_PRICES: 'planPrices',
  SUBSCRIPTIONS: 'subscriptions',
  CUSTOMERS: 'customers',
  USAGE: 'usage',
  QUOTES: 'quotes',
  ALERTS: 'alerts',
  SHIPMENTS: 'shipments',
  ORGS_SHIPMENTS: 'orgs_shipments',
  VESSEL_LOCATIONS: 'vesselLocations',
  SAVED_ITEMS: 'savedItems',
  SHARED_ITEMS: 'sharedItems',
  TAGS: 'tags',
  SHIPMENTS_TAGS: 'shipments_tags',
  TRACKING_REQUESTS: 'trackingRequests',
  USER_SETTINGS: 'userSettings',
  SHIPMENT_CHANGES: 'shipmentChanges',
  NOTIFICATIONS: 'notifications',
  USER_NOTIFICATIONS: 'userNotifications',
};

export const SHIPMENT_COLUMNS = {
  SHIPMENT_ID: 'shipmentId',
  TYPE: 'type',
  STATUS: 'status',
  ORIGIN_PORT_CODE: 'originPortCode',
  DESTINATION_PORT_CODE: 'destinationPortCode',
  CARRIER: 'carrier',
  ARRIVING: 'arriving',
  ETA: 'eta',
  ATA: 'ata',
  TAGS: 'tags',
};

export const DATA_TABLE_LAYOUTS = {
  TABLE: 'table',
  TIMELINE: 'timeline',
} as const;

export const SUPABASE_RPC_NAMES = {
  GET_SHIPMENT_FILTER_VALUES: 'get_shipment_filter_values',
  QUERY_USER: 'query_user',
  QUERY_ORG_USERS: 'query_org_users',
  GET_QUOTE_COUNTS_BY_STATUS: 'get_quote_counts_by_status',
  GET_SHIPMENT_COUNTS_BY_STATUS: 'get_shipment_counts_by_status',
  GET_ORG_SHIPMENTS: 'get_org_shipments',
  DELETE_SHIPMENT_TAGS: 'delete_shipment_tags',
};

export const SUPABASE_STATUS = {
  DELETED: 204,
} as const;

export const SHIPMENT_REFERENCE_TYPES = {
  MBL_NUMBER: 'mblNumber',
  AWB_NUMBER: 'awbNumber',
  CONTAINER_NUMBER: 'containerNumber',
} as const;

export const SHIPMENT_STATUS = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  IN_PROGRESS: 'IN_PROGRESS',
  DELIVERED: 'DELIVERED',
  COMPLETED: 'COMPLETED',
  UNTRACKABLE: 'UNTRACKABLE',
  NOT_FOUND: 'NOT_FOUND',
} as const;

export const SHIPMENT_ACTIVE_STATUS = {
  DESTINATION_PORT: 'Destination Port',
  DESTINATION: 'Destination',
  TRANSHIPMENT_PORT: 'Transshipment Port',
  IN_TRANSIT: 'In Transit',
} as const;

export const PORT_CONNECTION_TYPES = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
};

export const CONNECTIONS_ARROW_ICON_SIZE = 12; // The maximum size of the SVG icon in pixels

// Cookies
export const COOKIE_REDIRECT_URL = 'flc_redirect';
export const COOKIE_SUBSCRIPTIONS = 'flc_subscriptions';
export const COOKIE_MAX_SEARCH_COUNT = 'flc_sc';
export const COOKIE_API_URL = 'flc_apiUrl';
export const COOKIE_ONBOARDED = 'flc_onboarded';
export const COOKIE_APP_NAV = 'flc_appNav';
export const COOKIE_INVITE_NOTIFICATION_SEEN = 'flc_inviteSeen';

// Local storage
export const LOCAL_STORAGE_APP_NAV = 'fluentAppNav';

export const MAX_ANON_SEARCH_COUNT = 3; // per day

export const API_ERROR = {
  BAD_REQUEST: 'Bad Request',
  UNAUTHORISED: 'Unauthorised',
  QUOTA_EXCEEDED: 'Quota exceeded',
  NOT_FOUND: 'Not found',
  PORT_NOT_FOUND: 'No port found',
  SHIPMENT_NOT_FOUND: 'Shipment not found',
  TRACKING_REQUEST_EXISTS: 'Pending requests already exists',
} as const;

export const OK = 'OK' as const;

// CMS Blocks and Posts
export const PLANS_BLOCK = 'PlansBlockRecord';
export const POST_TYPES = {
  BLOG: 'blog',
  PRESS: 'press',
  CASE_STUDIES: 'case-studies',
};

// Supabase error codes
export const SUPABASE_ERROR_CODES = {
  RESULTS_CONTAIN_NO_ROWS: 'PGRST116',
  DUPLICATE_KEY: '23505',
};

export const DRAWER_PORTAL_ROOT_ID = 'drawer-portal';

export const MAILCHIMP_TAGS = {
  // This is the only tag in this format and we should consider
  // changing it to snake_case to be consistent with the others
  FREIGHT_FORWARDER: 'Freight Forwarder',
};

export const MAILCHIMP_EVENTS = {
  ABANDONED_CHECKOUT: 'abandoned_checkout',
  SEARCH_LIMIT_REACHED: 'search_limit_reached',
  SCHEDULE_LIMIT_REACHED: 'schedule_limit_reached',
  USER_ONBOARDING: 'user_onboarding',
};

export const USAGE_CATEGORY = {
  SEARCH: 'search',
  SCHEDULE: 'schedule',
} as const;

export const EVENT_TYPE = {
  STOP: 'stop',
  BORDER_CROSSING: 'border-crossing',
} as const;

export const FLAGS = {
  JONES_ACT_PARTIAL: 'jonesActPartial',
  JONES_ACT_INVALID: 'jonesActInvalid',
};

export const IDENTIFIER_API_TYPES = [
  'unknown',
  'nodeId',
  'componentId',
  'faa',
  'ds100',
  'iata',
  'icao',
  'locode',
  'scac',
  'callSign',
  'vesselName',
  'vesselImo',
  'linkKey',
  'freightosId',
  'canonical',
  'country',
  'region',
  'city',
  'address',
  'mmsi',
  'premise',
] as const;

// Shipment tracking number types
export const TRACKING_TYPE = {
  MBOL: 'MBOL',
  AWB: 'AWB',
  CONTAINER: 'CONT',
} as const;

export const RELIABILITY_CHART_KEYS = {
  SCHEDULE_RELIABILITY: 'scheduleReliability',
  AVERAGE_DAYS_LATE: 'averageLate',
} as const;

export const SAVED_ITEM_TYPE = {
  ROUTE: 'route',
  SCHEDULE: 'schedule',
  SHIPMENT: 'shipment',
  TRACKING_FILTER: 'tracking-filter',
} as const;

export const SHARED_ITEM_TYPE = {
  SHIPMENT: 'shipment',
} as const;

export const SHARED = 'shared';

export const DEFAULT_PAGINATION: PaginationState = {
  pageIndex: 0,
  pageSize: 20,
};

export const TIMELINE_SCALES = {
  QUARTER: 'quarter',
  MONTH: 'month',
  WEEK: 'week',
} as const;

export interface AppNavItem {
  id: string;
  label: string;
  icon: any;
  entitlement?: string;
  featureFlag?: string;
  notification?: {
    id: FeatureNotification;
    tooltipContent: string;
  };
  subItems: {
    label: string;
    url: string;
    entitlement?: string;
  }[];
}

export const APP_NAV_ITEM_HOME: AppNavItem = {
  id: 'home',
  label: 'Dashboard',
  icon: Home,
  subItems: [
    {
      label: 'Dashboard',
      url: HOME_URL,
    },
  ],
};

export const APP_NAV_ITEMS: AppNavItem[] = [
  {
    id: 'routing',
    label: 'Routing',
    icon: GitCompare,
    subItems: [
      {
        label: 'New search',
        url: ROUTING_SEARCH_URL,
      },
      {
        label: 'Search history',
        url: `${ROUTING_SEARCH_URL}/history`,
      },
    ],
  },
  {
    id: 'port-insights',
    label: 'Port Insights',
    icon: Radar,
    subItems: [
      {
        label: 'Explore ports',
        url: PORTS_SEARCH_URL,
      },
    ],
  },
  {
    id: 'schedules',
    label: 'Schedules',
    icon: CalendarRange,
    subItems: [
      {
        label: 'Air schedules',
        url: `${SCHEDULE_SEARCH_URL}?mode=air`,
      },
      {
        label: 'Ocean schedules',
        url: `${SCHEDULE_SEARCH_URL}?mode=water`,
      },
    ],
  },
  {
    id: 'tracking',
    label: 'Tracking',
    icon: PackageSearch,
    notification: {
      id: FEATURE.TRACKING_NAV,
      tooltipContent: 'NEW',
    },
    subItems: [
      {
        label: 'Add shipments',
        url: `${TRACKING_IMPORT_URL}`,
        entitlement: USER_ACCESS_FEATURES.TRACKING,
      },
      {
        label: 'Active shipments',
        url: `${TRACKING_URL}`,
      },
    ],
  },
  // {
  //   id: 'activity',
  //   label: 'Activity',
  //   icon: ActivitySquare,
  //   subItems: [
  //     {
  //       label: 'Notifications',
  //       url: '/activity/notifications',
  //     },
  //     {
  //       label: 'Shipment updates',
  //       url: '/activity/shipment-updates',
  //     },
  //   ],
  // },
];

export const ACCOUNT_SIDE_NAV_ITEMS: NavGroupInterface[] = [
  {
    heading: 'Activity',
    items: [
      {
        label: 'Search history',
        icon: 'history',
        href: '/search/history',
      },
    ],
  },
  {
    heading: 'Manage account',
    items: [
      {
        label: 'Account information',
        icon: 'usercard',
        href: '/account',
      },
      {
        label: 'Delete account',
        icon: 'close-circle',
        href: '/account/delete',
      },
    ],
  },
  {
    heading: 'Workspace',
    needsAdminAccess: false,
    items: [
      {
        label: 'General settings',
        icon: 'settings',
        href: '/account/organisation/settings',
        needsAdminAccess: true,
      },
      {
        label: 'Members',
        icon: 'users',
        href: '/account/organisation/users',
        paidTierOnly: true,
      },
      {
        label: 'Subscriptions & Usage',
        icon: 'payment',
        href: '/account/organisation/billing',
      },
      {
        label: 'Invoices',
        icon: 'sticky-note',
        href: '/account/organisation/invoices',
        needsAdminAccess: true,
      },
    ],
  },
];

export const ADMIN_NAV_ITEMS = [
  {
    url: '/admin',
    label: 'Dashboard',
    visible: true,
  },
  {
    url: '/admin/quotes',
    label: 'Quotes',
    visible: true,
  },
  {
    url: '/admin/organisations',
    label: 'Organisations',
    visible: true,
  },
  {
    url: '/admin/users',
    label: 'Users',
    visible: true,
  },
  {
    url: '/admin/shipments',
    label: 'Shipments',
    visible: true,
  },
];

export const APP_NAV_SIZES = {
  open: '176px',
  closed: '56px',
  openMobile: '65vw',
};

export const SORTING = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export const QSTASH_QUEUES = {
  SHIPMENT_UPDATES: 'shipment_updates',
  TRACKING_REQUESTS: 'tracking_requests',
} as const;

export const DEFAULT_NUQS_CONFIG = {
  clearOnDefault: true,
  history: 'push',
} as const;

export const NOTIFICATION_TYPE = {
  TRACKING: 'tracking',
  INVITE: 'invite',
  SYSTEM: 'system',
} as const;

export const NOTIFICATION_TOPIC_TRACKING = {
  SHIPMENT_FOUND: 'shipment_found',
} as const;

export const PROMISE_STATUS = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
} as const;
